<template>
  <div class="shopSettingBox">
    <el-form :model="dataUpdate" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
      <el-form-item label="店铺名称：" prop="name">
        <el-input v-model="dataUpdate.name"></el-input>
      </el-form-item>
      <el-form-item label="店铺LOGO：" prop="logo">
        <ReadyUploadSource
          :showStyle="{
            width: '160px',
            height: '160px',
          }"
          @getSource="getImgResOne"
          @removeThis="removeRes"
          :path="dataUpdate.logo"
        ></ReadyUploadSource>
        <div class="tips">建议尺寸216px*216px，logo图片</div>
      </el-form-item>
      <el-form-item label="店铺简介：" prop="description">
        <el-input v-model="dataUpdate.description"></el-input>
      </el-form-item>
      <el-form-item label="联系人：" prop="contact_username">
        <el-input v-model="dataUpdate.contact_username"></el-input>
      </el-form-item>
      <el-form-item label="联系人电话：" prop="contact_number">
        <el-input v-model="dataUpdate.contact_number"></el-input>
      </el-form-item>
      <el-form-item label="客服电话：" prop="service_telephone">
        <el-input v-model="dataUpdate.service_telephone"></el-input>
      </el-form-item>
      <el-form-item label="店铺区域：" prop="logo">
        <div class="selectBox">
          <el-select v-model="province_name" placeholder="请选择" @change="val => sonArea(0, val)">
            <el-option v-for="(item, index) in area" :key="index" :label="item.name" :value="item.name"></el-option>
          </el-select>
          <el-select v-model="city_name" placeholder="请选择" @change="val => sonArea(1, val)">
            <el-option v-for="(item, index) in cityArr" :key="index" :label="item.name" :value="item.name"></el-option>
          </el-select>
          <el-select v-model="area_name" placeholder="请选择" @change="val => sonArea(2, val)">
            <el-option v-for="(item, index) in areaArr" :key="index" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="经纬度：" prop="logo">
        <div style="display: flex">
          <el-input disabled v-model="dataUpdate.longitude" placeholder="经度"></el-input>
          <el-input disabled v-model="dataUpdate.latitude" placeholder="纬度"></el-input>
          <el-button type="primary" size="medium" @click="position">定位</el-button>
        </div>
      </el-form-item>
      <el-form-item label="店铺地址：" prop="logo">
        <el-input v-model="dataUpdate.address" disabled placeholder="详细位置"></el-input>
      </el-form-item>
      <el-form-item label="店铺资质：" prop="management_lincese">
        <RichText @soninfo="val => (dataUpdate.management_lincese = val)" :richTxt="dataUpdate.management_lincese"></RichText>
      </el-form-item>
      <Preservation @preservation="preservation"></Preservation>
    </el-form>
    <!-- 地图弹框 -->
    <el-dialog title="提示" :visible.sync="mapFlag" width="52%">
      <div>
        <MapData ref="map" :search="searchAddress" @getValue="getValue" :lng="dataUpdate.longitude" :lat="dataUpdate.latitude"></MapData>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getAddress">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MapData from '../../store/components/Map';
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
import RichText from '@/components/richText';
export default {
  components: {
    MapData,
    Preservation,
    ReadyUploadSource,
    RichText,
  },
  data() {
    return {
      mapFlag: false,
      dataUpdate: {
        name: '',
        logo: '',
        contact_username: '',
        contact_number: '',
        description: '',
        label: '',
        province_id: '',
        city_id: '',
        area_id: '',
        address: '',
        longitude: '',
        latitude: '',
        management_lincese: '',
      },
      rules: {
        name: [{ required: true, message: '请输入店铺名称', trigger: 'blur' }],
        logo: [{ required: true, message: '', trigger: 'blur' }],
        contact_username: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contact_number: [{ required: true, message: '请输入联系人电话', trigger: 'blur' }],
        description: [{ required: true, message: '请输入店铺简介', trigger: 'blur' }],
        service_telephone: [{ required: true, message: '请输入客服电话', trigger: 'blur' }],
        management_lincese: [{ required: true, message: '请填入店铺资质', trigger: 'blur' }],
      },
      province_name: '',
      city_name: '',
      area_name: '',
      // 省级数据
      area: [],
      // 市级数据
      cityArr: [],
      // 区级数据
      areaArr: [],
      searchAddress: '',
      flag: false,
    };
  },
  created() {
    this.getArea();
  },
  mounted() {},
  methods: {
    // 开启地图选择定位
    position() {
      if (!this.dataUpdate.province_id || !this.dataUpdate.city_id || !this.dataUpdate.area_id) {
        this.$message({
          message: '请选择完整地理区域',
          type: 'warning ',
        });
        return;
      }
      this.searchAddress =
        this.area.find(item => item.id == this.dataUpdate.province_id).name +
        this.cityArr.find(item => item.id == this.dataUpdate.city_id).name +
        this.areaArr.find(item => item.id == this.dataUpdate.area_id).name +
        this.dataUpdate.address;
      this.mapFlag = true;
      this.$nextTick(() => {
        this.$refs.map.showDo();
      });
    },
    getValue(item) {
      this.searchAddress = item.value;
    },
    // 获取省市区信息
    getArea() {
      let that = this;
      this.$axios
        .get(this.$api.shopDiy.areaList, {
          tree: 1,
          level: 2,
        })
        .then(res => {
          if (res.code == 0) {
            that.area = res.result.list;
            that.flag = true;
          }
        });
    },
    // 获取店铺的设置信息
    getDefaultConfig() {
      let that = this;
      this.$axios.post(this.$api.shopDiy.shopinfo, {}).then(res => {
        if (res.code == 0) {
          if (!res.result.info || res.result.info.area_id == 0) return;
          that.dataUpdate = res.result.info;
          that.province_name = that.area.find(item => item.id == that.dataUpdate.province_id).name;
          that.cityArr = that.area.find(item => item.id == that.dataUpdate.province_id)._child;
          that.city_name = that.cityArr.find(item => item.id == that.dataUpdate.city_id).name;
          that.areaArr = that.cityArr.find(item => item.id == that.dataUpdate.city_id)._child;
          that.area_name = that.areaArr.find(item => item.id == that.dataUpdate.area_id).name;
          if (that.dataUpdate.area_id == 0) {
            that.dataUpdate.area_id = null;
          }
        }
      });
    },
    // 三级联动选择地区
    sonArea(status, val) {
      if (status == 0) {
        let index = this.area.find(item => item.name == val);
        this.dataUpdate.province_id = index.id;
        this.dataUpdate.city_id = null;
        this.cityArr = index._child;
        this.city_name = null;
        this.dataUpdate.area_id = null;
        this.areaArr = [];
        this.area_name = null;
      } else if (status == 1) {
        let index = this.cityArr.find(item => item.name == val);
        this.dataUpdate.city_id = index.id;
        this.city_name = index.name;
        this.dataUpdate.area_id = null;
        this.areaArr = index._child;
        this.area_name = null;
      } else if (status == 2) {
        let index = this.areaArr.find(item => item.name == val);
        this.dataUpdate.area_id = index.id;
      }
    },
    // 拿到图片
    getImgResOne(val) {
      this.dataUpdate.logo = val.path;
    },
    removeRes() {
      this.dataUpdate.logo = '';
    },
    // 获取选中的经纬度
    getAddress() {
      let obj = this.$refs.map.currentCoordinate;
      let item = this.$refs.map.address;
      this.dataUpdate.latitude = obj.lat;
      this.dataUpdate.longitude = obj.lng;
      this.dataUpdate.address = item.street + item.streetNumber + this.searchAddress;
      this.mapFlag = false;
    },
    // 保存修改后的数据
    preservation() {
      let that = this;
      if (
        !this.dataUpdate.name ||
        !this.dataUpdate.logo ||
        !this.dataUpdate.contact_username ||
        !this.dataUpdate.contact_number ||
        !this.dataUpdate.service_telephone ||
        !this.dataUpdate.province_id ||
        !this.dataUpdate.city_id ||
        !this.dataUpdate.area_id ||
        !this.dataUpdate.address
      ) {
        this.$message({
          message: '请将有*前缀的信息填写完整',
          type: 'warning',
        });
        return;
      }
      this.$axios
        .post(this.$api.shopDiy.setBase, {
          name: that.dataUpdate.name,
          logo: that.dataUpdate.logo,
          contact_username: that.dataUpdate.contact_username,
          contact_number: that.dataUpdate.contact_number,
          service_telephone: that.dataUpdate.service_telephone,
          description: that.dataUpdate.description,
          province_id: that.dataUpdate.province_id,
          city_id: that.dataUpdate.city_id,
          area_id: that.dataUpdate.area_id,
          address: that.dataUpdate.address,
          longitude: that.dataUpdate.longitude,
          latitude: that.dataUpdate.latitude,
          management_lincese: that.dataUpdate.management_lincese,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message({
              message: '修改设置成功',
              type: 'success',
            });
            this.$store.state.user.shopInfo.logo = that.dataUpdate.logo;
          }
        });
    },
  },
  watch: {
    flag(val) {
      if (val) {
        this.getDefaultConfig();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.shopSettingBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: #fff;
  padding: 20px 20px 60px 20px;
  font-size: 14px;
  .el-form {
    .el-form-item {
      width: 900px;
      /deep/ .el-form-item__content .el-input {
        width: 400px;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
    /**/
  }
  .selectBox {
    display: flex;
    .el-select {
      flex: 1;
      /deep/ .el-input {
        width: 100% !important;
      }
    }
  }
  .tips {
    font-size: 12px;
    color: #999;
  }
}
</style>
